














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CONVERT_IN_ASSIGNED } from '../../../store/actions';
import { ConservatorshipDetails, ConvertInAssigned } from '../../../types';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    ConservatorSelect,
    DatePicker
  }
})
export default class ConvertInAssignedDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(CONVERT_IN_ASSIGNED) convertInAssigned!: (params: ConvertInAssigned) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: ConvertInAssigned = {
    conservatorship_id: this.conservatorship.id,
    assigned: this.conservatorship.assigned,
    employee_id: this.conservatorship.conservator.id
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatorship_id: this.conservatorship.id,
        assigned: this.conservatorship.assigned,
        employee_id: this.conservatorship.conservator.id
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.convertInAssigned(this.value);
  }
}
